import {Component} from 'react'
import './index.css'

class ShowImagesData extends Component {
    state = {
        quentity: '',
        metalColor: '',
        metalKarat: '',
        DiamondQuality: '',
        fontStyle: '',
        letterHeight: '',
        customName: '',
    }



    getQuentityValue = e => {
        this.setState({quentity: e.target.value})
        console.log(e.target.value)
        
       
      }
    
    
      getMetalColorValueRoseGold = event => {
        this.setState({
          metalColor: event.currentTarget.value,
          activeButton: { ...this.state.activeButton, metalColor: 'Rose Gold' }
          
        })
       
        console.log(event.target.value)
        
      }
      getMetalColorValueGold = event => {
        this.setState({
            metalColor: event.currentTarget.value,
          activeButton: { ...this.state.activeButton, metalColor: 'Gold' }
        })
        console.log(event.target.value)
       
      }
      getMetalColorValuePlatinum =  event => {
        this.setState({
          metalColor: event.currentTarget.value,
          activeButton: { ...this.state.activeButton, metalColor: 'Platinum' }
        })
        
        console.log(event.target.value)
      }
    
      getMetalColorValue10KT = event => {
        this.setState({
          metalKarat: event.currentTarget.value,
          activeButton: { ...this.state.activeButton, metalKarat: '10KT' }
        })
        console.log(event.target.value)
       
      }
      getMetalColorValue14KT = event => {
        this.setState({
          metalKarat: event.currentTarget.value,
          activeButton: { ...this.state.activeButton, metalKarat: '14KT' }
        })
        
        console.log(event.target.value)
      }
      getMetalColorValue18KT =  event => {
        this.setState({
          metalKarat: event.currentTarget.value,
          activeButton: { ...this.state.activeButton, metalKarat: '18KT' }
        })
        
        console.log(event.target.value)
      }
    
    
      getMetalColorValueVS = event => {
        this.setState({DiamondQuality: event.currentTarget.value, activeButton: { ...this.state.activeButton, DiamondQuality: 'VS' }})
        console.log(event.target.value)
        
      }
      getMetalColorValueSI = event => {
        this.setState({DiamondQuality: event.currentTarget.value, activeButton: { ...this.state.activeButton, DiamondQuality: 'SI' }})
        
        console.log(event.target.value)
      }
      getMetalColorValueLAB =  event => {
        this.setState({DiamondQuality: event.currentTarget.value, activeButton: { ...this.state.activeButton, DiamondQuality: 'LAB' }})
       
        console.log(event.target.value)
      }
      
      getFontStyleValue = e => {
        this.setState({fontStyle: e.target.value})
       
        console.log(e.target.value)
        
      }
    
    
    
    getMetalColorValueMediun = event => {
        this.setState({letterHeight: event.currentTarget.value, activeButton: { ...this.state.activeButton, letterHeight: 'Medium' }})
     
        console.log(event.target.value)
      }
      
    getMetalColorValueLarge =  event => {
        this.setState({letterHeight: event.currentTarget.value,  activeButton: { ...this.state.activeButton, letterHeight: 'Large' }})
       
        console.log(event.target.value)
      }
    
      getCustomNameValue = e => {
        this.setState({customName: e.target.value})
        console.log(e.target.value)
      }



    makeimggg = async () => {   

          const {
      quentity,
      metalKarat,
      DiamondQuality,
      fontStyle,
      letterHeight,
      customName,
      metalColor,
    } = this.state;
  
    const make = parseInt(quentity);
      
        const url = `https://chandrajewellery.api.ls2.kenmarkserver.com/costing?quantity=${make}&metalColor=Rose%20Gold&metalKarat=18KT&DiamondQuality=SI&fontStyle=Regular&letterHeight=Medium&customName=aniket`;
      
        try {
            const response = await fetch(url);
            if (!response.ok) {
              throw new Error('Network response was not ok ' + response.statusText);
            }
            const findData = await response.json();
            console.log(findData)
          } catch (error) {
            console.error("Error fetching data: ", error);
          }
          
        
      }
      
      
    
      componentDidMount() {
        this.makeimggg()
      }

render(){
    return(
        <div>
            <h1>Hello</h1>
            <div className="values-container">
              <h1 className="value-head">DIAMOND NAME NECKLACE</h1>
              <div>
                <p className="style-num"> Style Number :&nbsp;&nbsp;####&nbsp;&nbsp;<span className='week-deli'>Approximately # Week Delivery</span>
                </p>
              </div>
              <div>
                <p className="style-num pri">
                  Price :&nbsp;&nbsp;<b>12</b>&nbsp;&nbsp;<span className='incl-taxes'>(incl. of all
                  taxes)</span>&nbsp;&nbsp;&nbsp;&nbsp;<span className='week-deli'>Custom Quote</span>
                </p>
              </div>
              <div className="input-container">
                <span className="quantity">Quantity :</span>{' '}&nbsp;
                <input
                  type="text"
                  placeholder="1"
                  onChange={this.getQuentityValue}
                  className='quali'
                />
              </div>
              <div className="input-container">
                <span className="quantity">Metal Color :</span>{' '}&nbsp;
                <div className='color-button-container'>
                  <button  onClick={this.getMetalColorValueRoseGold} value="Rose Gold">
                  Rose Gold
                  </button>
                  <button onClick={this.getMetalColorValueGold} value="Gold">
                            Gold
                  </button>
                  <button  onClick={this.getMetalColorValuePlatinum} value="Platinum">
                  Platinum
                  </button>
                </div>
              </div>
              <div className="input-container">
                <span className="quantity">Metal Karat :</span>{' '}&nbsp;
                <div className='color-button-container'>
                  <button onClick={this.getMetalColorValue10KT} value="10KT">10KT</button>
                  <button onClick={this.getMetalColorValue14KT} value="14KT"> 14KT</button>
                  <button onClick={this.getMetalColorValue18KT} value="18KT">18KT</button>
                </div>
              </div>
              <div className="input-container">
                <span className="quantity">Diamond Quality :</span>{' '}&nbsp;
                <div className='color-button-container'>
                <button
                  
                  onClick={this.getMetalColorValueVS}
                  value="VS"
                >
                  VS
                </button>
                <button
                 
                  onClick={this.getMetalColorValueSI}
                  value="SI"
                >
                  SI
                </button>
                <button
                  
                  onClick={this.getMetalColorValueLAB}
                  value="LAB"
                >
                  LAB
                </button>

                </div>
              </div>
              <div className="input-container">
                <span className="quantity">Font Style :</span>{' '}&nbsp;
                <select onChange={this.getFontStyleValue} className="qua">
                  <option>Regular</option>
                  <option>Sport</option>
                </select>
              </div>
              <div className="input-container">
                <span className="quantity">Letter Height :</span>{' '}&nbsp;
                <div className='color-button-container'>
                <button
                      
                      onClick={this.getMetalColorValueMediun}
                      value="Medium"
                    >
                      Medium
                    </button>
                    <button
                      
                      onClick={this.getMetalColorValueLarge}
                      value="Large"
                    >
                      Large
                    </button>

                </div>
              </div>
              <div>
                <p className="style-num cat">Chain Type :&nbsp;&nbsp;<span className='week-deli'>Cat 30 Cable Chain with Lobster Lock</span></p>
              </div>
              <div className="style-cont-name">
                <span className="quantity">Custom name :</span>
                <input
                  type="text"
                  placeholder="Enter Name"
                  onChange={this.getCustomNameValue}
                  className="name-input"
                />
                <button onClick={this.makeimggg} className="call-button">
                  Preview Name
                </button>
              </div>
            </div>
        </div>
    )
}




}

export default ShowImagesData